import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home, AboutUs, OurBuisness } from "./pages";
import FoodProcessing from "./pages/FoodProcessing";
import AgricBuisness from "./pages/AgricBuisness";
import BioEnergy from "./pages/BioEnergy";
import NotFound from "./pages/NotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/aboutUs",
    element: <AboutUs />,
  },
  {
    path: "/ourBuisness",
    element: <OurBuisness />,
  },
  {
    path: "/food",
    element: <FoodProcessing />,
  },
  {
    path: "/agric",
    element: <AgricBuisness />,
  },
  {
    path: "/bio",
    element: <BioEnergy />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
