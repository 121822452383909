import React, { useState } from "react";

function Footer({ route = true }) {
  const [email, setEmail] = useState("");

  /*  const handleSubmit = (e) => {
    e.preventDefault();
      const config = {
      Host: "smtp.elasticemail.com",
      Username: "admin@gia-africa.org",
      Password: "1ED0F683EF9CC06817B2672D08CE811F9804",
      To: "lucasifeoluwa@yahoo.com",
      From: email,
      Subject: "Let us connect",
      Body: `${email} wants to connect`,
    };
    try {
      if (window.Email) {
        window.Email.send(config).then(() => alert("email sent succesfully"));
        console.log(window.Email);
      }
    } catch (error) {
      console.error(error);
    }
    window.Email.send({
      Host: "smtp.elasticemail.com",
      Username: "lucasifeoluwa712@gmail.com",
      Password: "904F1E4787FF0AE4B81E8518068B9B7BC6C5",
      To: "lucasifeoluwa712@gmail.com",
      From: "lucasifeoluwa712@gmail.com",
      Subject: "Let us connect",
      Body: `${email} wants to connect`,
    }).then((message) => alert(message));
  }; */
  return (
    <div
      id="footer"
      className="flex flex-col relative lg:gap-[150px] bg-primary px-[20px] lg:px-[100px] mt-[100px] lg:mt-[200px] "
    >
      <div>
        <div className="flex flex-col lg:flex-row w-[full] mt-[-30px] lg:mt-[-70px]  justify-between">
          {route === false && (
            <div className="flex flex-col font-[900] text-[24px] lg:text-[56px] ">
              <p className="text-primary">LEARN MORE ABOUT OUR</p>
              <p className="text-green">SERVICES PROJECTS, AND</p>
              <p className="text-green">
                INITIATIVES. <span className="text-white">CONTACT US</span>{" "}
              </p>
              <p className="text-white">TODAY</p>
              <p className=" text-[24px] font-[700] text-green">
                Green<span className="text-secondary">Lands</span>{" "}
              </p>
            </div>
          )}
          {route === true && (
            <div className="flex flex-col font-[900] text-[20px] lg:text-[40px] ">
              <p className="text-primary">JOIN US IN OUR JOURNEY TO</p>
              <p className="text-green">
                CULTIVATE <span className="text-white">A BRIGHTER FUTURE</span>{" "}
              </p>
              <p className="text-white">
                FOR <span className="text-green">AGRICULTURE AND THE</span>{" "}
              </p>
              <p className="text-green">PLANET</p>
              <p className=" text-[20px] font-[700] text-green">
                Green<span className="text-secondary">Lands</span>{" "}
              </p>
            </div>
          )}
          <div className="lg:border-[8px] my-[20px] lg:my-[0px] border-[4px] flex justify-center items-center p-[10px] lg:p-[20px] h-[150px] lg:h-[222px] bg-white rounded-[8px] lg:rounded-[16px] border-[#212121] lg:mt-[-50px] ">
            <form
              action="https://formspree.io/f/xbjnbryk"
              method="POST"
              className="flex flex-col  items-center justify-center"
            >
              <input
                name="email"
                type="email"
                value={email}
                placeholder="Your Email"
                onChange={(e) => setEmail(e.target.value)}
                className=" w-[300px] lg:w-[329px] p-1 lg:p-2 mb-1 lg:mb-3 border border-gray-300 rounded"
              />
              <button
                /* onClick={handleSubmit} */
                type="submit"
                className=" p-1 lg:py-3 px-4 lg:px-8 rounded-[8px] w-[300px] lg:w-[353px] items-center justify-center gap-2 flex flex-row border-[1px] bg-green cursor-pointer "
              >
                <p className="font-[700] text-[16px] lg:text-[18px] text-[#fff] ">
                  Contact Us
                </p>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5 0.5V2H12.9425L0.5 14.4425L1.5575 15.5L14 3.0575V12.5H15.5V0.5H3.5Z"
                    fill="#fff"
                    stroke="#fff  "
                    stroke-width="0.5"
                  />
                </svg>
              </button>
            </form>
          </div>
        </div>
        <div className="flex flex-col lg:mt-10 mb-[20px] lg:mb-0 lg:flex-row relative gap-[20px] lg:gap-[200px] text-white mt-[10px] ">
          <div className="flex flex-col text-[12px] lg:text-[16px] gap-[15px] text-center lg:text-start font-[400] ">
            <p>
              Unit 2 Wetland estate phase 3, Aguwan Anaekwe street, Wuye Abuja
              Nigeria
            </p>
            <p>11 Julius gbigbi crescent Magodo GRA Lagos Nigeria</p>
            <p>1055 Budapest, spent istvan krt, 27, Hungary.</p>
            <p>72c 634 Roy Hume rd riverdale Georgia 30274-1746, USA</p>
          </div>
          <div className="flex items-center lg:items-start flex-col gap-[20px]">
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center gap-[40px] lg:gap-[200px] rounded-[20px] border-t border-white py-[20px] lg:px-[40px] px-[10px] text-[10px] lg:text-[14px] font-[400] text-white ">
        <p>All rights reserved. Copyrights © 2022 Ltd.</p>

        <div className="flex gap-[10px] items-center cursor-pointer ">
          <div className="w-[22px] h-[22px]">
            <img
              src={require("../assests/twitter.png")}
              alt=""
              className="w-full h-full object-contain "
            />
          </div>
          <div className="w-[22px] h-[22px]">
            <img
              src={require("../assests/insta.png")}
              alt=""
              className="w-full h-full object-contain "
            />
          </div>
          <div className="w-[22px] h-[22px]">
            <img
              src={require("../assests/facebook.png")}
              alt=""
              className="w-full h-full object-contain "
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
