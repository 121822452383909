import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";

const bgImg = require("../assests/Image.png");
const fanBg = require("../assests/fanbg.png");

function Home() {
  return (
    <div className="w-full h-full">
      <Header />
      <div
        className=" bg-cover bg-center bg-no-repeat bg-fixed  mix-blend-multiply bg-primary lg:py-10 lg:px-[100px] p-[20px] w-full h-full "
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="  ">
          <p className="lg:text-[18px] text-[12px] lg:text-start text-center lg:font-[400] text-white ">
            WELCOME TO GREENLANDS
          </p>
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="  flex-[0.7] text-center lg:text-start my-[20px] lg:my-0  w-full ">
              <p className=" font-bold lg:text-[88px] text-[20px] text-white ">
                <span className="text-green">CULTIVATING </span>
                CONNECTIONS NOURISHING LIVES."
              </p>
            </div>
            <div className=" flex flex-col items-center lg:items-start w-[full] gap-3 flex-[0.4] lg:self-center  ">
              <div>
                <p className="lg:text-[24px] text-[14px] font-[400] text-center lg:text-start my-[20px] lg:my-0 leading-[30px] lg:leading-[40px] text-white ">
                  At Greenlands, we're not just consultants –
                </p>
                <p className="lg:text-[24px] text-[14px] font-[400] text-center lg:text-start my-[20px] lg:my-0 leading-[30px] lg:leading-[40px] text-white ">
                  We're your dedicated partners in agribusiness success. Contact
                  us today to embark on a journey of unparalleled growth and
                  prosperity in the dynamic world of agribusiness.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[full] lg:mt-[64px] mt-[20px] mb-[-125px] lg:mb-[-250px]  lg:h-[460px] h-[230px] border-[12px] border-solid border-[#212121] rounded-[12px]  ">
          <img
            src={require("../assests/home.png")}
            alt="hero"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <div className=" flex w-full lg:flex-row items-center lg:items-start  flex-col gap-[20px] lg:gap-[152px] px-[20px] lg:px-[100px] mt-[175px] lg:mt-[350px] ">
        <div className=" items-center  min-w-fit lg:items-start lg:gap-5">
          <p className="text-primary text-center lg:text-start text-[24px] lg:text-[40px] font-[700] ">
            About Us.
          </p>
          <div className="flex items-center gap-1 lg:gap-3">
            <div className="border cursor-pointer border-solid min-w-fit flex border-primary rounded-[30px] lg:py-3 py-1 px-4 lg:px-[23px] items-center justify-center ">
              <p className="text-primary text-[12px] lg:text-[16px] font-[500]  ">
                <NavLink to="/aboutUs">About us</NavLink>
              </p>
            </div>
            <div className="border cursor-pointer border-solid min-w-fit border-[#bdbdbd] rounded-[30px] lg:py-3 py-1 px-4 lg:px-[23px] items-center justify-center ">
              <p className="text-[#bdbdbd] text-[12px] lg:text-[16px] font-[500]  ">
                Our business
              </p>
            </div>
          </div>
        </div>
        <div className=" flex flex-col gap-[10px] lg:gap-[48px] lg:w-[800px]  ">
          <p className=" hidden lg:flex lg:text-[40px]  text-primary font-[800] ">
            Greenlands
          </p>
          <p className="lg:text-[24px] text-primary text-[14px] text-center lg:text-start font-[400] leading-[32px] lg:leading-[40px]  ">
            We're redefining the landscape of agribusiness with a holistic
            approach to food manufacturing, agribusiness consulting, and
            bioenergy solutions. With a steadfast commitment to innovation,
            sustainability, and excellence, we cultivate a brighter future for
            farmers, communities, and the planet.
          </p>
          <NavLink to="/aboutUs" className="lg:self-start self-center ">
            <div className="bg-green rounded-[8px] px-2 py-1 lg:px-8 lg:py-4 gap-2 flex items-center  hover:opacity-80 cursor-pointer ">
              <p className="text-white lg:text-[18px] text-[14px] font-[700] ">
                Discover
              </p>
              <div className="hidden lg:flex">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5 0.5V2H12.9425L0.5 14.4425L1.5575 15.5L14 3.0575V12.5H15.5V0.5H3.5Z"
                    fill="#ffffff"
                    stroke="#ffffff"
                    stroke-width="0.5"
                  />
                </svg>
              </div>
              {/*  <div className="hidden sm:block ">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5 0.5V2H12.9425L0.5 14.4425L1.5575 15.5L14 3.0575V12.5H15.5V0.5H3.5Z"
                    fill="#ffffff"
                    stroke="#ffffff"
                    stroke-width="0.5"
                  />
                </svg>
              </div> */}
            </div>
          </NavLink>
        </div>
      </div>
      <div className=" lg:gap-[152px] px-[20px] lg:px-[100px] mt-[50px] lg:mt-[100px] ">
        <div className="gap-5">
          <p className="text-primary cursor-pointer text-center lg:text-start text-[24px] lg:text-[40px] font-[800] ">
            Our Business
          </p>
          <div className="flex justify-center lg:justify-start gap-1 lg:gap-3">
            <div className="border cursor-pointer border-solid min-w-fit flex border-primary rounded-[30px] lg:py-3 py-1 px-4 lg:px-[23px] items-center justify-center ">
              <p className="text-primary text-[12px] lg:text-[16px] font-[500]  ">
                <NavLink to="/ourBuisness"> Our Business </NavLink>
              </p>
            </div>
            <div className="border cursor-pointer border-solid min-w-fit border-[#bdbdbd] rounded-[30px] lg:py-3 py-1 px-4 lg:px-[23px] items-center justify-center ">
              <p className="text-[#bdbdbd] text-[12px] lg:text-[16px] font-[500]  ">
                About Us
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex lg:flex-row flex-col border-t-[1px] justify-between mt-[40px] lg:px-[40px] px-[20px] py-[8px] lg:py-[30px] border-solid border-[#000] rounded-[20px] ">
            <div className="flex gap-2">
              <p className="lg:text-[16px] text-[10px] text-primary font-[400] ">
                01
              </p>
              <div className="mt-[1px]">
                <p className="text-primary text-[16px] lg:text-[32px] font-[700] ">
                  FOOD MANUFACTURING
                </p>
              </div>
            </div>
            <div className="flex lg:flex-row items-center flex-col-reverse gap-[10px] justify-between">
              <div className="flex relative items-center ">
                <div className="lg:w-[300px] w-[200px] h-[150px] lg:h-[220px] rounded-[8px] overflow-hidden ">
                  <img
                    src={require("../assests/home5.png")}
                    alt="biz"
                    className="w-full h-full object-contain"
                  />
                </div>
                <NavLink to="/ourBuisness">
                  <div className="rounded-full border-1 relative bottom-[-50px]  right-[30px] border-white  w-[52px] h-[52px] ">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="2"
                        y="2"
                        width="56"
                        height="56"
                        rx="28"
                        fill="#21D13D"
                      />
                      <rect
                        x="2"
                        y="2"
                        width="56"
                        height="56"
                        rx="28"
                        stroke="white"
                        stroke-width="4"
                      />
                      <path
                        d="M26.25 23.75V25H34.1187L23.75 35.3687L24.6312 36.25L35 25.8812V33.75H36.25V23.75H26.25Z"
                        fill="#031D44"
                        stroke="#031D44"
                        stroke-width="0.5"
                      />
                    </svg>
                  </div>
                </NavLink>
              </div>
              <div className="flex items-center gap-8 ">
                <div className="w-[350px]">
                  <p className=" text-[14px] mt-[20px] text-primary lg:mt-0 lg:text-[16px] lg:text-start text-center lg:flex-start font-[500] leading-[21px] ">
                    Embark on a journey of discovery as we harness the power of
                    nature to unlock new frontiers in food manufacturing. From
                    wholesome ingredients to delectable products, we deliver
                    quality, taste, and nutrition
                  </p>
                </div>
                <div className=" hidden lg:flex w-[18px] bg-primary border-[2px] border-primary h-1  " />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex lg:flex-row flex-col border-t-[1px] justify-between mt-[40px] lg:px-[40px] px-[20px] py-[8px] lg:py-[30px] border-solid border-[#000] rounded-[20px] ">
            <div className="flex gap-2">
              <p className="lg:text-[16px] text-primary text-[10px] font-[400] ">
                02
              </p>
              <div className="mt-[1px]">
                <p className="text-primary text-[16px] lg:text-[32px] font-[700] ">
                  AGRIBUSINESS MANAGEMENT
                </p>
              </div>
            </div>
            <div className="flex lg:flex-row items-center flex-col-reverse gap-[10px] justify-between">
              <div className="flex relative items-center ">
                <div className="lg:w-[300px] w-[200px] h-[150px] lg:h-[220px] rounded-[8px] overflow-hidden ">
                  <img
                    src={require("../assests/home1.png")}
                    alt="biz"
                    className="w-full h-full object-contain"
                  />
                </div>
                <NavLink to="/ourBuisness">
                  <div className="rounded-full border-1 relative bottom-[-50px]  right-[30px] border-white  w-[52px] h-[52px] ">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="2"
                        y="2"
                        width="56"
                        height="56"
                        rx="28"
                        fill="#21D13D"
                      />
                      <rect
                        x="2"
                        y="2"
                        width="56"
                        height="56"
                        rx="28"
                        stroke="white"
                        stroke-width="4"
                      />
                      <path
                        d="M26.25 23.75V25H34.1187L23.75 35.3687L24.6312 36.25L35 25.8812V33.75H36.25V23.75H26.25Z"
                        fill="#031D44"
                        stroke="#031D44"
                        stroke-width="0.5"
                      />
                    </svg>
                  </div>
                </NavLink>
              </div>
              <div className="flex items-center gap-8 ">
                <div className="w-[350px]">
                  <p className="text-[14px] mt-[20px] text-primary lg:mt-0 lg:text-[16px] lg:text-start text-center lg:flex-start font-[500] leading-[21px] ">
                    Explore the depths of agribusiness turnaround management and
                    agribusiness project management. With tailored strategies
                    and actionable insights, we empower our clients to thrive in
                    a rapidly evolving industry.
                  </p>
                </div>
                <div className=" hidden lg:flex w-[18px] bg-primary border-[2px] border-primary h-1  " />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex lg:flex-row flex-col border-t-[1px] justify-between mt-[40px] lg:px-[40px] px-[20px] py-[8px] lg:py-[30px] border-solid border-[#000] rounded-[20px] ">
            <div className="flex gap-2">
              <p className="lg:text-[16px] text-primary text-[10px] font-[400] ">
                03
              </p>
              <div className="mt-[1px]">
                <p className="text-primary text-[16px] lg:text-[32px] font-[700] ">
                  BIOENERGY
                </p>
              </div>
            </div>
            <div className="flex lg:flex-row items-center flex-col-reverse gap-[10px] justify-between">
              <div className="flex relative items-center ">
                <div className="lg:w-[300px] w-[200px] h-[150px] lg:h-[220px] rounded-[8px] overflow-hidden ">
                  <img
                    src={require("../assests/bio1.png")}
                    alt="biz"
                    className="w-full h-full object-contain"
                  />
                </div>
                <NavLink to="/ourBuisness">
                  <div className="rounded-full border-1 relative bottom-[-50px] right-[30px] border-white  w-[52px] h-[52px] ">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="2"
                        y="2"
                        width="56"
                        height="56"
                        rx="28"
                        fill="#21D13D"
                      />
                      <rect
                        x="2"
                        y="2"
                        width="56"
                        height="56"
                        rx="28"
                        stroke="white"
                        stroke-width="4"
                      />
                      <path
                        d="M26.25 23.75V25H34.1187L23.75 35.3687L24.6312 36.25L35 25.8812V33.75H36.25V23.75H26.25Z"
                        fill="#031D44"
                        stroke="#031D44"
                        stroke-width="0.5"
                      />
                    </svg>
                  </div>
                </NavLink>
              </div>
              <div className="flex items-center gap-8 ">
                <div className="w-[350px]">
                  <p className="text-[14px] text-primary mt-[20px] lg:mt-0 lg:text-[16px] lg:text-start text-center lg:flex-start font-[500] leading-[21px] ">
                    Join us at the forefront of the renewable energy revolution
                    as we harness the potential of bioenergy to fuel progress
                    and preserve the planet. From biofuels to biogas and
                    carbonomics, we're pioneering sustainable solutions that
                    reduce carbon emissions, mitigate climate change, and create
                    a cleaner, greener future.
                  </p>
                </div>
                <div className=" hidden lg:flex w-[18px] bg-primary border-[2px] border-primary h-1  " />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${fanBg})` }}
        className="lg:py-[40px] relative py-[20px] my-[20px] lg:px-[100px] px-[20px] bg-cover bg-center  bg-no-repeat bg-fixed mix-blend-multiply flex lg:flex-row flex-col items-center justify-between "
      >
        <div className="flex flex-col gap-[10px]  ">
          <div className=" w-full lg:w-[900px] ">
            <p className=" text-[24px] text-primary text-center lg:text-start lg:text-[48px] font-[500] ">
              Renewable energy can be achieved with focus on Agriculture
            </p>
            <div className=" hidden lg:flex ml-40 w-[150px] lg:w-[295px]  bottom-[-5px] h-2 lg:h-4 rounded-[8px] bg-[#00A54E] " />
          </div>
        </div>
        <div className="hidden  lg:flex lg:w-[376px] lg:h-[616px]  ">
          <img
            src={require("../assests/home4.png")}
            alt="fan"
            className="w-full h-full object-contain "
          />
        </div>
      </div>
      <div className="flex lg:flex-row flex-col gap-[50px] justify-between px-[20px] lg:px-[100px] py-[16px] lg:py-[40px] ">
        <div className="flex flex-col-reverse lg:flex-col gap-[10px] lg:gap-[52px]">
          <p className=" text-green text-center lg:text-start font-[500]  text-[24px] lg:text-[40px] ">
            Trusted by.
          </p>
          <p className=" text-primary font-[500] self-center text-center lg:text-start text-[16px] w-[337px] ">
            We know that we are stronger together, and to cultivate a better
            future needs many hands. We partner with innovative agriculture
            businesses who recognise the world is ripe for change.
          </p>
        </div>
        <div className=" h-auto lg:h-[320px] w-full lg:w-auto  ">
          <img
            src={require("../assests/home7.png")}
            alt=""
            className="w-full h-full object-contain lg:object-fill"
          />
        </div>
      </div>
      {/* <div className="flex flex-col gap-[10px] my-[40px]  ">
        <div className="flex lg:flex-row flex-col gap-[50] justify-between px-[20px] lg:px-[100px] py-[20px] lg:py-[100px] ">
          <div className="flex flex-col gap-[5px] lg:gap-[24px]">
            <p className=" text-green text-center lg:text-start font-[500] text-[24px] lg:text-[40px] ">
              What we're doing.
            </p>
            <p className="text-center text-green lg:text-start font-[500] text-[12px] lg:text-[24px]  ">
              Renewable energy across the whole nation and more
            </p>
          </div>
          <div className="flex justify-between  mt-[10px] lg:mt-[0p] lg:gap-[48px] ">
            <div className=" lg:text-start ">
              <p className="text-green  text-[24px] lg:text-[40px] font-[700] ">
                900 +
              </p>
              <p className=" text-[10px] text-[#031D44] lg:text-[16px] font-[400] ">
                {" "}
                Landowners
              </p>
              <p className=" text-[10px] text-[#031D44] lg:text-[16px] font-[400] ">
                earning from clean energy
              </p>
            </div>
            <div className=" lg:text-start ">
              <p className="text-green text-[24px] lg:text-[40px] font-[700] ">
                {" "}
                50
              </p>
              <p className=" text-[10px] text-[#031D44] lg:text-[16px] font-[400] ">
                {" "}
                Projects in Development
              </p>
              <p className=" text-[10px] text-[#031D44] lg:text-[16px] font-[400] ">
                12 GW+ pipeline
              </p>
            </div>
            <div className=" lg:text-start ">
              <p className="text-green text-[24px] lg:text-[40px] font-[700] ">
                {" "}
                20
              </p>
              <div>
                <p className=" text-[10px] text-[#031D44] lg:text-[16px] font-[400] ">
                  {" "}
                  Projects under
                </p>
                <p className=" text-[10px] text-[#031D44] lg:text-[16px] font-[400] ">
                  {" "}
                  Management
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[full] px-[20px] lg:px-[100px] flex flex-col justify-center, items-center ">
          <img
            src={require("../assests/map1.png")}
            alt="map1"
            className="w-full lg:h-[420px] object-contain "
          />
        </div>
      </div> */}

      <Footer route={false} />
    </div>
  );
}

export default Home;
