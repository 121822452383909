import React from "react";
import { NavLink } from "react-router-dom";

function Header({ footerRef }) {
  return (
    <div className="flex flex-row items-center justify-between bg-primary  lg:px-[100px] p-[20px]">
      <div className="">
        <img
          alt=""
          src={require("../assests/logo.png")}
          className="lg:w-[236px] lg:h-[64px] w-[100px] h-[30px]  object-contain"
        />
        <p className=" text-[5px] lg:text-[12px] lg:text-center font-[400] lg:font-[700] lg:mt-2 text-white ">
          INTEGRATED AGRIBUSNESSES GROUP
        </p>
        <p className=" text-[5px] lg:text-[12px] lg:text-center font-[400] lg:font-[700] text-white ">
          EXPERTISE. EXPERIENCE. ENTERPRISE.
        </p>
      </div>
      <ul className=" flex flex-row lg:text-[18px] text-[12px] justify-between gap-[10px] lg:gap-[64px] items-center text-white font-[500]  ">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "underline" : "hover:font-[900]"
          }
        >
          Home
        </NavLink>
        <NavLink
          to="/aboutUs"
          className={({ isActive }) =>
            isActive ? "underline" : "hover:font-[900]"
          }
        >
          About Us
        </NavLink>
        <NavLink
          to="/ourBuisness"
          className={({ isActive }) =>
            isActive ? "underline" : "hover:font-[900]"
          }
        >
          Our Business
        </NavLink>
      </ul>
      <a
        href="#footer"
        className=" hover:opacity-80 hidden lg:py-3 py-1 h-12 px-2 lg:px-8 rounded-[8px] max-w-fit items-center justify-center gap-2 lg:flex flex-row border-[1px] border-green cursor-pointer "
      >
        <p className="font-[700] text-[14px] lg:text-[18px] text-green ">
          Contact Us
        </p>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 0.5V2H12.9425L0.5 14.4425L1.5575 15.5L14 3.0575V12.5H15.5V0.5H3.5Z"
            fill="#21D13D"
            stroke="#21D13D"
            stroke-width="0.5"
          />
        </svg>
      </a>
    </div>
  );
}

export default Header;
