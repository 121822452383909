import React from "react";

function FoodProcessing() {
  return (
    <div className="flex flex-col mt-[40px] lg:mt-[0px] gap-[20px] lg:gap-[40px]">
      <div className="lg:w-[665px] flex lg:self-end ">
        <p className=" text-[14px] lg:text-[20px] text-center font-[500] lg:font-[400] text-[#000]  ">
          At AgroVision, we operate state-of-the-art food manufacturing
          facilities equipped with the latest technology and adhering to the
          highest industry standards. Our experienced team ensures that every
          step of the food manufacturing process – from sourcing quality
          ingredients to packaging the final product – is carried out with
          precision and care. We take pride in delivering nutritious, delicious
          food products that meet the needs and preferences of consumers
          worldwide.
        </p>
      </div>
      <div className=" w-full lg:w-[700px] lg:h-[370px]  ">
        <img
          src={require("../assests/farm1.png")}
          alt=""
          className=" w-full h-full object-contain "
        />
      </div>
      <div className="flex justify-between">
        <div className="lg:w-[124px] w-[100px] h-[80px] lg:h-[102px] ">
          <img
            src={require("../assests/farm2.png")}
            alt=""
            className=" w-full h-full object-contain "
          />
        </div>
        <div className="lg:w-[124px] w-[100px] h-[80px] lg:h-[102px]">
          <img
            src={require("../assests/farm3.png")}
            alt=""
            className=" w-full h-full object-contain "
          />
        </div>
        <div className="lg:w-[124px] w-[100px] h-[80px] lg:h-[102px] ">
          <img
            src={require("../assests/farm4.png")}
            alt=""
            className=" w-full h-full object-contain "
          />
        </div>
        <div className="hidden lg:flex  w-[255px] h-[6px] rounded-[10px] bg-[#e9d9cc] self-end ">
          <div className="w-[152px] h-[6px] rounded-[10px] bg-orange " />
        </div>
      </div>
    </div>
  );
}

export default FoodProcessing;
