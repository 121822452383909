import React from "react";
const fanBg = require("../assests/fanbg.png");

function BioEnergy() {
  return (
    <div className="flex flex-col lg:px-[150px] px-[20px] my-[50px] lg:my-[0px] lg:py-[40px]">
      <div className="flex  lg:flex-row flex-col gap-10 lg:gap-40 justify-between">
        <img
          src={require("../assests/bio8.png")}
          alt=""
          className="lg:w-[532px] lg:h-[351px] object-contain "
        />
        <div className="flex flex-col gap-5 lg:w-[665px] w-[full] justify-end ">
          <p className="text-green text-center lg:text-start text-[20px] lg:text-[24px] font-[700] ">
            Research and Development Initiatives:
          </p>
          <p className="lg:text-[20px]  text-primary text-[14px] text-center lg:text-start font-[400] ">
            We fully employ latest advancements in agricultural technology and
            innovation, such as precision farming, use of drones, collaboration
            with research institutions, experimentation and adoption of
            cutting-edge techniques and agricultural inputs for crop and animals
            production and improved productivity/profitability.
          </p>
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${fanBg})` }}
        className="mt-[100px]  bg-cover bg-center  bg-no-repeat bg-fixed mix-blend-multiply flex lg:flex-row flex-col items-center justify-between "
      >
        <div className="flex flex-col gap-[10px] lg:gap-[40px] ">
          <div className=" ">
            <p className="text-primary text-[24px] text-center lg:text-start lg:text-[48px] font-[500] ">
              Renewable energy can be achieved with focus on Agriculture
            </p>
            <div className=" hidden lg:flex  w-[150px] lg:w-[295px]  bottom-[-5px] h-2 lg:h-4 rounded-[8px] bg-[#00A54E] " />
          </div>
        </div>
        <div className="hidden  lg:flex lg:w-[376px] lg:h-[628px] ">
          <img
            src={require("../assests/home4.png")}
            alt="fan"
            className="w-full h-full object-contain "
          />
        </div>
      </div>
    </div>
  );
}

export default BioEnergy;
