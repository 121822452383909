import React from "react";

function AgricBuisness() {
  return (
    <div className="flex flex-col mt-[40px] lg:mt-[0px] gap-[20px] lg:gap-[40px]">
      <div className="lg:w-[665px] flex lg:self-end  ">
        <p className="text-[14px] lg:text-[20px] text-center font-[500] lg:font-[400] text-[#000]   ">
          Our agricultural business consulting services are designed to empower
          farmers, agribusinesses, and stakeholders with the knowledge and tools
          they need to thrive in a rapidly changing industry. Whether you are
          looking to improve crop yields, optimize supply chain logistics, or
          navigate regulatory challenges, our team of experts is here to provide
          personalized guidance and support. We believe in fostering long-term
          partnerships based on trust, integrity, and shared success.
        </p>
      </div>
      <div className="w-full lg:w-[700px] lg:h-[370px] ">
        <img
          src={require("../assests/agric1.png")}
          alt=""
          className=" w-full h-full object-contain "
        />
      </div>
      <div className="flex justify-between">
        <div className="lg:w-[124px] w-[100px] h-[80px] lg:h-[102px]">
          <img
            src={require("../assests/agric2.png")}
            alt=""
            className=" w-full h-full object-contain "
          />
        </div>
        <div className="lg:w-[124px] w-[100px] h-[80px] lg:h-[102px] ">
          <img
            src={require("../assests/agric3.png")}
            alt=""
            className=" w-full h-full object-contain "
          />
        </div>
        <div className="lg:w-[124px] w-[100px] h-[80px] lg:h-[102px] ">
          <img
            src={require("../assests/agric4.png")}
            alt=""
            className=" w-full h-full object-contain "
          />
        </div>
        <div className=" hidden lg:flex w-[255px] h-[6px] rounded-[10px] bg-[#e9d9cc] self-end ">
          <div className="w-[152px] h-[6px] rounded-[10px] bg-orange " />
        </div>
      </div>
    </div>
  );
}

export default AgricBuisness;
