import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BioEnergy from "./BioEnergy";

const bgImg = require("../assests/Image.png");

function OurBuisness() {
  const [activePage, setActivePage] = useState("Food");

  const handleNavClick = (page) => {
    setActivePage(page);
  };

  return (
    <div>
      <Header />
      <div
        className=" bg-cover  bg-center bg-no-repeat bg-fixed  mix-blend-multiply bg-primary lg:py-10 lg:px-[100px] p-[20px] w-full h-full "
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="  ">
          <p className="lg:text-[18px] text-[12px] lg:text-start text-center lg:font-[400] text-white ">
            WELCOME TO GREEN LANDS
          </p>
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="  lg:w-[960px] text-center lg:text-start my-[20px] lg:my-0  w-full ">
              <p className=" font-[900] lg:text-[88px] text-[20px] text-white ">
                OUR BUSINESS
              </p>
            </div>
          </div>
        </div>
        {activePage === "Food" && (
          <div className="w-[full] lg:mt-[64px] mt-[20px] mb-[-125px] lg:mb-[-100px]  lg:h-[460px] h-[230px] border-[12px] border-solid border-[#212121] rounded-[12px]  ">
            <img
              src={require("../assests/ourbuisness.png")}
              alt="hero"
              className="w-full h-full object-cover"
            />
          </div>
        )}
        {activePage === "Agric" && (
          <div className="w-[full] lg:mt-[64px] mt-[20px] mb-[-125px] lg:mb-[-100px]  lg:h-[460px] h-[230px] border-[12px] border-solid border-[#212121] rounded-[12px]  ">
            <img
              src={require("../assests/agribuisness.png")}
              alt="hero"
              className="w-full h-full object-cover"
            />
          </div>
        )}
        {activePage === "Bio" && (
          <div className="w-[full] lg:mt-[64px] mt-[20px] mb-[-125px] lg:mb-[-100px]  lg:h-[460px] h-[230px] border-[12px] border-solid border-[#212121] rounded-[12px]  ">
            <img
              src={require("../assests/bio9.png")}
              alt="hero"
              className="w-full h-full object-cover"
            />
          </div>
        )}
      </div>
      <div className="flex lg:gap-[100px] lg:flex-row flex-col lg:justify-between mt-[150px] lg:mt-[150px]  p-[20px] lg:px-[100px] ">
        <div className="flex lg:flex-col  justify-evenly lg:gap-[100px]  ">
          <div className="flex w-1/3 lg:w-4/6 lg:flex-row flex-col items-center lg:items-start g-2 lg:gap-[10px]">
            <p className="lg:text-[16px] text-[12px] font-[400] text-primary">
              01
            </p>
            <div className="lg:mt-1  text-center lg:text-start  font-[700]  flex flex-col lg:w-[519px] cursor-pointer ">
              <p
                className={
                  activePage === "Food"
                    ? "text-green lg:text-[36px] text-[10px]"
                    : "text-primary  lg:text-[24px] text-[10px] "
                }
                onClick={() => handleNavClick("Food")}
              >
                <p>CONSULTING SERVICES</p>
              </p>
            </div>
          </div>
          <div className="flex w-1/3 lg:w-5/6   lg:flex-row flex-col items-center lg:items-start g-2 lg:gap-[10px]">
            <p className="lg:text-[16px] text-[12px] font-[400] text-primary">
              02
            </p>
            <div className="lg:mt-1  text-center lg:text-start font-[700] flex flex-col cursor-pointer ">
              <p
                className={
                  activePage === "Agric"
                    ? "text-green lg:text-[36px] text-[10px]"
                    : "text-primary  lg:text-[24px] text-[10px] "
                }
                onClick={() => handleNavClick("Agric")}
              >
                AGRIBUSINESS MANAGEMENT
              </p>
            </div>
          </div>
          <div className="flex w-1/3  lg:flex-row flex-col items-center lg:items-start g-2 lg:gap-[10px]">
            <p className="lg:text-[16px] text-[12px] font-[400] text-primary">
              03
            </p>
            <div className="lg:mt-1  text-center lg:text-start  font-[700]  flex flex-col  cursor-pointer ">
              <p
                className={
                  activePage === "Bio"
                    ? "text-green lg:text-[36px] text-[12px]"
                    : "text-primary  lg:text-[24px] text-[12px] "
                }
                onClick={() => handleNavClick("Bio")}
              >
                BIOENERGY
              </p>
            </div>
          </div>
        </div>
        {activePage === "Food" && (
          <div className="hidden lg:flex flex-1 mb-14 justify-end  ">
            <div className=" hidden lg:flex bg-green h-[75px] w-[80px]" />
            <div className=" hidden lg:flex bg-[#6D9D75] h-[75px] w-[80px] mt-[75px]" />
          </div>
        )}
        {activePage === "Agric" && (
          <div className="hidden lg:flex flex-1 mb-14 justify-between ">
            <div className=" hidden lg:flex bg-primary h-[75px] w-[80px]" />
            <div className=" hidden lg:flex right-0 justify-end self-end bg-white h-[75px] w-[80px] mt-[75px]" />
          </div>
        )}
        {activePage === "Bio" && (
          <div className="flex  flex-col lg:gap-20 gap-10 lg:mt-[0px] mt-[50px] ">
            <div className="flex flex-col gap-5 lg:w-[665px] w-[full] ">
              <p className="text-green text-center lg:text-start  text-[18px] lg:text-[24px] font-[700] ">
                Renewable Energy Solutions for Agribusiness: 
              </p>
              <p className="lg:text-[20px] text-primary text-[14px] text-center lg:text-start font-[400] ">
                Not neglecting the importance of reducing carbon footprint in
                agricultural processes, we highlight renewable energy solutions
                for agribusiness, including solar power, biogas, biofuels and
                wind energy from waste energy production and conversion of some
                crops into bio-energy.
              </p>
            </div>
            <div className="flex flex-col gap-5 lg:w-[665px] w-[full] ">
              <p className="text-green text-center lg:text-start text-[18px] lg:text-[24px] font-[700] ">
                Sustainable Farming Practices and Techniques:
              </p>
              <p className="lg:text-[20px] text-primary text-[14px] text-center lg:text-start font-[400] ">
                Our community prioritizes eco-friendly practices, biodiversity
                conservation, and soil health, such as agroforestry, integrated
                pest management, and precision agriculture that reduce
                environmental impact, promote long-term viability and promote a
                greener future for your business.
              </p>
            </div>
          </div>
        )}
      </div>
      {activePage === "Food" && (
        <div className="flex-col">
          <div className="flex lg:flex-row gap-[20px] lg:my-[0] flex-col justify-between p-[20px] lg:p-[100px] lg:items-start items-center ">
            <div className=" w-full h-[300px] lg:w-[508px] lg:h-[518px] rounded-[10px] ">
              <img
                src={require("../assests/consult1.png")}
                alt=""
                className=" w-full h-full object-contain "
              />
            </div>
            <div className=" flex gap-[10px] lg:gap-0 lg:flex-col flex-col lg:w-[640px]">
              <p className=" text-[18px] text-center lg:text-start lg:text-[32px] font-[700] text-green  ">
                Strategic Planning in Agriculture:
              </p>
              <div className="flex flex-col gap-[3px] ">
                <p className="text-[14px] lg:text-[20px] text-center lg:text-start text-primary  font-[400] leading-[25px] lg:leading-[36px] ">
                  {" "}
                  Our expert team collaborates closely with businesses to
                  develop customized strategies tailored to the unique needs of
                  the agricultural sector. From in-depth market research &
                  analysis, financials, cash flows management and returns on
                  investment analysis, consumer trends and competition analysis,
                  markets pricing and forecasts, and trade patterns to
                  meticulous assessment of risks associated with weather,
                  regulatory challenges, and market dynamics, we ensure that
                  your investments are positioned for unparalleled success. Our
                  bespoke business plans for agricultural ventures include
                  financial projections, market entries, strategies and
                  operational plans, delivering a complete project lifecycle.
                  Your products stand out in the market places, because we
                  explore relevant markets, and leverage branding & marketing
                  strategies to distinguish your products from others.
                </p>
              </div>
            </div>
          </div>
          <div className="flex  lg:flex-row-reverse gap-[10px] mb-[40px] lg:my-[0] flex-col justify-between p-[20px] lg:p-[100px] lg:items-start items-center ">
            <div className=" w-full h-[220px] lg:w-[442px] lg:h-[241px] rounded-[10px] ">
              <img
                src={require("../assests/cosult2.png")}
                alt=""
                className=" w-full h-full object-contain "
              />
            </div>
            <div className=" flex gap-[10px]  lg:gap-0 lg:flex-col flex-col lg:w-[640px]">
              <p className=" text-[18px] text-center lg:text-start lg:text-[32px] font-[700] text-green  ">
                Maximizing Returns on Agricultural Investments:
              </p>
              <div className="flex flex-col gap-[3px] ">
                <p className="text-[14px] text-center lg:text-start lg:text-[20px] text-primary  font-[400] leading-[25px] lg:leading-[36px] ">
                  {" "}
                  Unlock the full potential of your agricultural investments
                  with our invaluable insights into evaluating and diversifying
                  your investment portfolios. We carefully plan and
                  strategically implement asset allocation techniques that
                  empower you to generate optimal returns and thrive in the
                  competitive agricultural landscape.
                </p>
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row-reverse gap-[10px] mb-[40px] lg:my-[0] flex-col justify-between p-[20px] lg:p-[100px] lg:items-start items-center   ">
            <div className=" w-full lg:w-[360px] lg:h-[345px] rounded-[10px] ">
              <img
                src={require("../assests/consult3.png")}
                alt=""
                className=" w-full h-full object-contain "
              />
            </div>
            <div className=" flex gap-[10px] lg:gap-0 lg:flex-col flex-col lg:w-[640px]">
              <p className=" text-[18px] text-center lg:text-start lg:text-[32px] font-[700] text-green  ">
                Efficient Distribution Channels for Agricultural Products:
              </p>
              <div className="flex flex-col gap-[3px] ">
                <p className="text-[14px] lg:text-[20px] text-primary lg:text-start text-center  font-[400] lg:leading-[36px] leading-[px] ">
                  {" "}
                  We seamlessly navigate the critical phase of your crops,
                  livestock, and livestock production, harvesting, utilisation
                  and distribution with our expertise. Whether it's connecting
                  you with reputable and efficient distribution networks or
                  facilitating lucrative partnerships with food processing and
                  other value-adding companies as off-takers, we ensure that
                  your end products reach the right markets-space for maximum
                  profitability in both local and global markets. To access and
                  penetrate different markets for agricultural produce, we
                  provide guidance on quality, consumer trends, improved quality
                  inputs (hybrids) and demand, using global standards like AGOA
                  and CDI
                </p>
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row gap-[10px]  my-[40px] lg:my-[0] flex-col justify-between p-[20px] lg:p-[100px] lg:items-start items-center   ">
            <div className=" w-full lg:w-[550px] lg:h-[400px] rounded-[10px] ">
              <img
                src={require("../assests/consult4.png")}
                alt=""
                className=" w-full h-full object-contain "
              />
            </div>
            <div className=" flex gap-[20px] lg:gap-0 lg:flex-col flex-col lg:w-[640px]">
              <p className=" text-[18px] text-center lg:text-start lg:text-[32px] font-[700] text-green  ">
                Funding Solutions for Agricultural Ventures
              </p>
              <div className="flex flex-col gap-[20px] ">
                <p className="text-[14px] lg:text-[20px] text-center lg:text-start text-primary  font-[400] leading-[25px]  lg:leading-[36px]">
                  Securing funding is crucial to the success of any agricultural
                  venture. Our team supports businesses in accessing various
                  funding sources, by critically analyzing their eligibility
                  criteria, application processes and compliance. These funding
                  sources include: 
                </p>
                <div className="flex flex-col lg:items-start  gap-[10px] lg:gap-5">
                  <div className="flex flex-row gap-3">
                    <img
                      src={require("../assests/link.png")}
                      alt="link"
                      className="w-8 h-8 object-contain"
                    />
                    <p className=" text-[20px] text-green font-[700] ">
                      Loans, Grants and Subsidies: 
                    </p>
                  </div>
                  <p className=" text-[16px]  text-primary font-[400] ">
                    We help you navigate available loans, government funding
                    programs, grants, and subsidies aimed at supporting
                    agribusinesses.
                  </p>
                </div>
                <div className="flex flex-col lg:items-start  gap-[10px] lg:gap-5">
                  <div className="flex flex-row gap-3">
                    <img
                      src={require("../assests/link.png")}
                      alt="link"
                      className="w-8 h-8 object-contain"
                    />
                    <p className=" text-[20px] text-green font-[700] ">
                      Investor Partnerships: 
                    </p>
                  </div>
                  <p className=" text-[16px] text-primary font-[400] ">
                    Through our extensive network, we connect you with potential
                    investors who share your vision for agricultural
                    innovation. 
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*  <div className="flex lg:flex-row my-[40px] lg:my-[0] lg:gap-80 flex-col justify-center p-[20px] lg:p-[100px] items-center ">
            <div className=" w-full lg:w-[584px] lg:h-[595px] rounded-[10px] ">
              <img
                src={require("../assests/specialist.png")}
                alt=""
                className=" w-full h-full object-contain "
              />
            </div>
            <div className=" flex gap-[40px] lg:gap-0 lg:flex-col flex-col-reverse lg:w-[640px]">
              <p className=" text-[20px] text-center lg:text-start lg:text-[32px] font-[700] text-green  ">
                We farm with both organic and conventional techniques and strive
                to produce food and fiber in the most ethical and
                environmentally friendly ways possible.
              </p>
              <div className="flex flex-col gap-[3px] ">
                <p className="text-[16px] lg:text-[22px] text-primary  font-[500 ">
                  {" "}
                  - Funsho Bayowa
                </p>
                <p className=" text-[12px] lg:text-[14px] text-primary font-[400]   ">
                  Agro specialist at GreenLands
                </p>
              </div>
            </div>
          </div> */}
        </div>
      )}
      {activePage === "Agric" && (
        <div className="flex flex-col ">
          <div className="flex lg:flex-row gap-[20px] lg:my-[0] flex-col justify-between p-[20px] lg:p-[100px] lg:items-start items-center ">
            <div className="flex lg:self-start self-center flex-col  ">
              <div className="lg:w-[530px] w-full h-[250px] lg:h-[546px] z-10   ">
                <img
                  src={require("../assests/agric1.png")}
                  alt=""
                  className="w-full h-full object-contain "
                />
              </div>
              <div className="hidden lg:flex  mt-[-150px] lg:mt-[-300px] lg:ml-[50px] ml-[25px]  ">
                <img
                  src={require("../assests/wiggly.png")}
                  alt=""
                  className="lg:w-[525px]  w-full object-contain h-[200px] lg:h-[396px] "
                />
              </div>
            </div>
            <div className="flex flex-col gap-[20px] lg:gap-20">
              <div className="flex flex-col gap-5 lg:w-[665px] w-[full] ">
                <p className="text-green text-center lg:text-start text-[20px] lg:text-[24px] font-[700] ">
                  Agricultural Process Optimization:
                </p>
                <p className="lg:text-[20px] text-primary text-[14px] text-center lg:text-start font-[400] ">
                  Enhance efficiency and profitability with our cutting-edge
                  agricultural process optimization services. Our team conducts
                  comprehensive process flow analysis to streamline your
                  operations, minimize waste, and maximize productivity, and
                  offers guidance on analyzing and optimizing various stages of
                  agricultural processes. 
                </p>
              </div>
              <div className="flex flex-col gap-5 lg:w-[665px] w-[full] ">
                <p className="text-green text-center lg:text-start text-[20px] lg:text-[24px] font-[700] ">
                  Sustainable Farming Practices and Techniques:
                </p>
                <p className="lg:text-[20px] text-primary text-[14px] text-center lg:text-start font-[400] ">
                  Our community prioritizes eco-friendly practices, biodiversity
                  conservation, and soil health, such as agroforestry,
                  integrated pest management, and precision agriculture that
                  reduce environmental impact, promote long-term viability and
                  promote a greener future for your business.
                </p>
              </div>
            </div>
          </div>
          <div className="flex   lg:flex-row-reverse flex-col gap-[20px] justify-between px-[20px] lg:px-[150px] my-[10px] lg:mt-[10px] pt-[50px] lg:pt-[20px]  lg:pb-[100px] ">
            <div className="lg:w-[615px] w-full  self-center h-[230px] lg:h-[386px] ">
              <img
                src={require("../assests/agric2.png")}
                alt=""
                className="w-full h-full object-contain "
              />
            </div>
            <div className="flex flex-col  gap-[40px] lg:gap-20">
              <div className="flex flex-col gap-5 lg:w-[665px] w-[full] ">
                <p className="text-green text-center lg:text-start text-[20px] lg:text-[24px] font-[700] ">
                  Compliance: 
                </p>
                <p className="lg:text-[20px] text-primary text-[14px] text-center lg:text-start font-[400] ">
                  Stay updated about relevant regulatory updates, policy
                  changes, and compliance requirements affecting the
                  agribusiness sector, and receive guidance on navigating
                  regulatory complexities and ensuring compliance in your
                  agribusiness.
                </p>
              </div>
              <div className="flex flex-col gap-5 lg:w-[665px] w-[full] ">
                <p className="text-green text-center lg:text-start text-[20px] lg:text-[24px] font-[700] ">
                  Training and Seminars on Agribusiness: 
                </p>
                <p className="lg:text-[20px] text-primary text-[14px] text-center lg:text-start font-[400] ">
                  Acquire adequate and relevant information and knowledge on
                  various agribusiness topics, including strategic planning,
                  financial management, sustainable farming practices, and
                  market access strategies, to enhance your skills from our
                  workshops, seminars, and training programs. As farmers and
                  agri-entrepreneurs, take full advantage of our skill
                  development programs,  vocational training initiatives and
                  resources aimed at improving productivity, profitability, and
                  resilience.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col  gap-[20px] justify-between px-[20px] lg:px-[150px] my-[10px] lg:mt-[10px] pt-[50px] lg:pt-[20px]  lg:pb-[100px] ">
            <p className=" text-[24px] lg:text-[36px] lg:text-start text-center font-[700] text-green ">
              LIVESTOCK FACTORY
            </p>
            <div className="flex  lg:gap-10 lg:flex-row-reverse gap-[20px] flex-col-reverse justify-between  ">
              <div className="flex-col">
                <div className="lg:w-[615px] w-full rounded-[8px] h-[220px] lg:h-[439px] ">
                  <img
                    src={require("../assests/agric3.png")}
                    alt=""
                    className="w-full h-full object-contain "
                  />
                </div>
              </div>
              <div className="flex flex-col gap-5 lg:w-[665px] w-[full] ">
                <p className="text-green text-center lg:text-start text-[20px] lg:text-[24px] font-[700] ">
                  Premium Livestock care: 
                </p>
                <p className="lg:text-[20px] text-primary text-[14px] text-center lg:text-start font-[400] ">
                  We employ best practices for ensuring the welfare and
                  well-being of livestock, including proper housing, nutrition,
                  healthcare, and handling techniques for high product quality.
                  From feeding to harvesting, our livestock are specially fed
                  and raised in environments free of harmful chemicals. 
                </p>
                <div className="flex gap-2 flex-row lg:self-center items-center lg:gap-[20px]  ">
                  <div className="flex-0.5">
                    <img
                      className=" w-full lg:w-[216px] lg:h-[163px] object-contain "
                      src={require("../assests/agric4.png")}
                      alt=""
                    />
                  </div>
                  <div className="flex-0.5">
                    <img
                      className=" w-full lg:w-[216px] lg:h-[163px] object-contain "
                      src={require("../assests/agric5.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {activePage === "Bio" && <BioEnergy />}
      <Footer />
    </div>
  );
}

export default OurBuisness;
