import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const bgImg = require("../assests/Image.png");

function AboutUs() {
  return (
    <div>
      <Header />
      <div
        className=" bg-cover  bg-center bg-no-repeat bg-fixed  mix-blend-multiply bg-primary lg:py-10 lg:px-[100px] p-[20px] w-full h-full "
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="  ">
          <p className="lg:text-[18px] text-[12px] lg:text-start text-center lg:font-[400] text-white ">
            WELCOME TO GREENLANDS
          </p>
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="  lg:w-[960px] text-center lg:text-start my-[20px] lg:my-0  w-full ">
              <p className=" font-[900] lg:text-[88px] text-[20px] text-white ">
                WHO WE ARE
              </p>
            </div>
          </div>
        </div>
        <div className="w-[full] lg:mt-[64px] mt-[20px] mb-[-125px] lg:mb-[-250px]  lg:h-[460px] h-[230px] border-[12px] border-solid border-[#212121] rounded-[12px]  ">
          <img
            src={require("../assests/aboutus.png")}
            alt="hero"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <div className="flex lg:flex-row flex-col-reverse gap-[20px] justify-between px-[20px] lg:px-[150px] my-[100px] lg:mt-[100px] pt-[100px] lg:pt-[200px]  lg:pb-[100px] ">
        <div className="flex  flex-col">
          <div className="flex gap-[10px] lg:gap-0  flex-row  ">
            <div className="lg:w-[370px] rounded-[8px]  w-[200px] h-[120px]  lg:h-[296px] ">
              <img
                src={require("../assests/new3.png")}
                alt=""
                className="w-full h-full object-contain "
              />
            </div>
            <div className="flex lg:ml-[-50px] self-end lg:mt-[100px] ">
              <img
                src={require("../assests/new2.png")}
                alt=""
                className="lg:w-[271px] w-[265px] rounded-[8px] object-contain h-[170px] lg:h-[225px] z-10 "
              />
              <div className=" hidden lg:flex  h-[75px] w-[80px]" />
            </div>
          </div>
          <div className="flex gap-[10px] flex-row self-center ">
            <div className="lg:w-[271px] w-[200px] rounded-[8px] h-[130px] lg:h-[225px] ">
              <img
                src={require("../assests/new4.png")}
                alt=""
                className="w-full h-full object-contain "
              />
            </div>
            <div className="flex relative rounded-[8px] bg-black mt-4 self-end ">
              <img
                src={require("../assests/new1.png")}
                alt=""
                className="lg:w-[482px] rounded-[8px] w-[265px] object-contain h-[140px] lg:h-[346px]  "
              />
              <div className=" hidden absolute right-[-50px] top-[-50px] lg:flex bg-white h-[75px] w-[80px] z-10" />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5 lg:w-[665px] w-[full] ">
          <p className="text-primary text-center lg:text-start text-[24px] lg:text-[40px] font-[700] ">
            Greenlands
          </p>
          <p className="lg:text-[20px] text-primary text-[16px] text-center lg:text-start font-[400] ">
            Is a leading agribusiness committed to driving innovation and
            sustainability in the agriculture sector. With a focus on food
            manufacturing, agricultural business consulting, and bioenergy
            solutions, we are dedicated to revolutionizing the way food is
            produced, distributed, and consumed.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-[40px] lg:gap-[80px] lg:px-[150px] p-[20px] lg:py-[100px] ">
        <div className="flex lg:flex-row gap-[20px] flex-col justify-between ">
          <div className="flex flex-col">
            <p className="text-[16px] text-primary font-[400]  ">01</p>
            <div className="flex justify-center gap-[10px]">
              <div className="lg:mt-1 flex flex-col gap-[10px] lg:gap-[40px] w-[519px] ">
                <p className="lg:text-[32px] text-[24px] text-center lg:text-start font-[700] text-primary ">
                  OUR VISION
                </p>
                <p className="lg:text-[32px] text-center lg:text-start text-[20px] font-[700] text-green ">
                  "To become the leading Integrated Agribusiness Conglomerate in
                  the Sub-Saharan African markets with utmost emphasis on
                  creating value that is desired and acquired."
                </p>
              </div>
            </div>
          </div>
          <div className=" w-full h-[250px]  items-center lg:items-start lg:w-[592px] lg:h-[457px] ">
            <img
              src={require("../assests/about1.png")}
              alt=""
              className="w-full h-full object-contain "
            />
          </div>
        </div>
        <div className="flex relative lg:flex-row-reverse flex-col justify-between">
          <div className="flex absolut flex-col  ">
            <p className="text-[16px] font-[400] text-primary ">02</p>
            <div className="flex justify-center gap-[10px]">
              <div className="lg:mt-1 flex flex-col gap-[10px] lg:gap-[40px] w-[519px] ">
                <p className="lg:text-[32px] text-[24px] text-primary text-center lg:text-start font-[700]  ">
                  OUR MISSION
                </p>
                <p className="lg:text-[32px] text-center lg:text-start text-[20px] font-[700] text-green ">
                  "To create product and services that are desired and acquired
                  by our cliental globally.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:flex gap-5 mt-[10px] items-center lg:items-start ">
            <img
              src={require("../assests/about8.png")}
              alt=""
              className="w-full h-[250px] lg:w-[370px] lg:h-[414px] object-contain "
            />
            <div className="hidden lg:flex ">
              <div className=" hidden lg:flex bg-green h-[75px] w-[80px] mt-[75px]" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full my-[50px] lg:my-0 flex-col gap-[20px] lg:gap-[40px] p-[20px] lg:px-[150px] lg:py-[100px] ">
        <div>
          <p className="text-[24px] font-[700] text-primary text-center ">
            OUR VALUES
          </p>
        </div>
        <div className="flex lg:flex-row flex-col lg:justify-center lg:gap-[200px] gap-[40px] ">
          <div className="flex flex-col items-center lg:w-[273px] lg:gap-[10px] ">
            <svg
              width="61"
              height="60"
              viewBox="0 0 61 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4584_20798)">
                <path
                  d="M60.5 0H0.5V60H60.5V0Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <path
                  d="M30.5 25C36.0228 25 40.5 20.5228 40.5 15C40.5 9.47715 36.0228 5 30.5 5C24.9772 5 20.5 9.47715 20.5 15C20.5 20.5228 24.9772 25 30.5 25Z"
                  fill="white"
                  stroke="#21D13D"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M53 55C53 42.5736 42.9264 32.5 30.5 32.5C18.0736 32.5 8 42.5736 8 55"
                  stroke="#21D13D"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30.5 55L35.5 48.75L30.5 32.5L25.5 48.75L30.5 55Z"
                  fill="white"
                  stroke="#21D13D"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_4584_20798">
                  <rect
                    width="60"
                    height="60"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>

            <p className=" text-[20px] lg:text-[32px] font-[700] text-[#21D13D]">
              EXPERIENCE
            </p>
            <p className="text-[14px] lg:text-[16px] font-[400] text-primary text-center leading-[20px] ">
              Following the highest quality standards backed by years of
              experience and been commited to delivering the best to customers
              and stakeholders
            </p>
          </div>
          <div className="flex flex-col items-center lg:w-[273px] lg:gap-[10px] ">
            <svg
              width="61"
              height="60"
              viewBox="0 0 61 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30.5 0.46875L38.8333 8.02875L50.08 7.59541L51.6033 18.7488L60.5 25.6421L54.5 35.1621L56.8833 46.1654L46.1667 49.5988L40.92 59.5554L30.5 55.2988L20.08 59.5554L14.8333 49.5988L4.11666 46.1654L6.5 35.1621L0.5 25.6421L9.39666 18.7488L10.92 7.59541L22.1667 8.02875L30.5 0.46875ZM30.5 4.96875L24.4033 10.4987L23.3967 11.4121L22.0367 11.3621L13.81 11.0454L12.6967 19.1987L12.5133 20.5487L11.4367 21.3787L4.93334 26.4221L9.32 33.3887L10.0467 34.5354L9.75666 35.8688L8.01666 43.9121L15.8533 46.4254L17.15 46.8421L17.7867 48.0454L21.62 55.3254L29.24 52.2154L30.5 51.6987L31.76 52.2154L39.38 55.3254L43.2167 48.0454L43.85 46.8421L45.1467 46.4254L52.9833 43.9121L51.2433 35.8688L50.9533 34.5354L51.68 33.3887L56.07 26.4221L49.5633 21.3787L48.4867 20.5487L48.3033 19.1987L47.1867 11.0454L38.9667 11.3621L37.6033 11.4121L36.5967 10.4987L30.5 4.96875ZM41.158 24.1401L43.1747 26.7967L25.6247 40.1167L17.6547 32.1468L20.0113 29.7901L25.9247 35.7034L41.158 24.1401Z"
                fill="#21D13D"
              />
            </svg>

            <p className=" text-[20px] lg:text-[32px] font-[700] text-[#21D13D] ">
              EXPERTISE
            </p>

            <p className=" text-[14px] lg:text-[16px] font-[400] text-primary text-center leading-[20px] ">
              We invest in research, development, and technology to drive
              continuous improvement, optimize efficiency, and address emerging
            </p>
          </div>
          <div className="flex flex-col items-center lg:w-[273px]  lg:gap-[10px] ">
            <svg
              width="61"
              height="60"
              viewBox="0 0 61 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3916_6087)">
                <path
                  d="M38.868 20.2969L21.4961 37.5394L22.908 38.9569L40.2798 21.7144L38.868 20.2969Z"
                  fill="#21D13D"
                />
                <path
                  d="M13.5055 40.9964C7.44172 40.9964 2.51047 36.0633 2.51047 30.0014C2.51047 23.9395 7.44359 19.0064 13.5055 19.0064C16.4417 19.0064 19.1061 20.1708 21.0805 22.0514L21.0823 22.0402L21.1836 22.1283L25.0761 26.002H21.5042V28.0008H28.5017V21.0033H26.503V24.5995L22.5955 20.7108L22.5861 20.7202C20.2405 18.4252 17.038 17.0039 13.5073 17.0039C6.34297 17.0039 0.511719 22.8333 0.511719 29.9995C0.511719 37.1658 6.34109 42.9952 13.5073 42.9952C17.203 42.9952 20.533 41.437 22.903 38.9527L21.4911 37.5389C19.4848 39.6614 16.6517 40.9964 13.5073 40.9964H13.5055Z"
                  fill="#21D13D"
                />
                <path
                  d="M47.4947 17.0058C44.1853 17.0058 41.1703 18.2583 38.8753 20.3039L40.2872 21.7158C42.2184 20.032 44.7366 19.0045 47.4947 19.0045C53.5584 19.0045 58.4897 23.9377 58.4897 29.9995C58.4897 36.0614 53.5566 40.9945 47.4947 40.9945C44.5697 40.9945 41.9147 39.8395 39.9422 37.9702L39.9384 37.9889L33.3178 31.3927L31.8984 32.8008L38.5266 39.4045L38.5378 39.3933C40.8722 41.6189 44.0222 42.9952 47.4928 42.9952C54.6572 42.9952 60.4884 37.1658 60.4884 29.9995C60.4884 22.8333 54.6591 17.0039 47.4928 17.0039L47.4947 17.0058Z"
                  fill="#21D13D"
                />
              </g>
              <defs>
                <clipPath id="clip0_3916_6087">
                  <rect
                    width="60"
                    height="60"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>

            <p className=" text-[20px] lg:text-[32px] font-[700] text-[#21D13D] ">
              ENTERPRISE
            </p>
            <p className=" text-[14px] lg:text-[16px] font-[400] text-primary text-center leading-[20px] ">
              Sustainable agriculture practices that preserve natural resources,
              protect biodiversity, and mitigate climate change.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="flex mb-[150px] items-center mt-[10px] lg:flex-row flex-col lg:px-[100px] px-[20px] lg:py-[100px] gap-4 lg:gap-10 ">
        <div className=" w-full lg:w-[760px] lg:h-[407px]  ">
          <img
            src={require("../assests/about5.png")}
            alt=""
            className="w-full h-full object-contain "
          />
        </div>
        <div className="flex lg:flex-col h-full relative lg:flex-1 lg:justify-between justify-end ">
          <div className="hidden lg:flex flex-1 mb-14  ">
            <div className=" hidden lg:flex bg-green h-[75px] w-[80px]" />
            <div className=" hidden lg:flex bg-primary h-[75px] w-[80px] mt-[75px]" />
          </div>
          <p className=" text-[18px] lg:text-start lg:text-[32px] font-[500] text-primary lg:w-[745px] ">
            At AgroVision, we are passionate about making a positive impact on
            the world and are committed to building a more sustainable and
            resilient agriculture sector for future generations.
          </p>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

export default AboutUs;
